.Quiz {
    background-color: #eff2f9;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Quiz .progress {
    height: 2.5rem;
    font-size: large;
    text-align: center;
    background-color: #E0E2DB;
    border-radius: 50px;
}

.Quiz .progress-bar {
    background-color: #D03642;
}

.Quiz .progress-bar span{
    position: absolute;
    margin-left: 15px;
    font-weight: bold;
    font-family: 'Open Sans';
}

.Quiz .question {
    margin-top: 20px;
}

.Quiz .question .area span {
    font-size: xx-large;
}

.Quiz .question .area {
    margin-top: 10px;
    text-align: left;
}

.Quiz .question .area span {
    margin-right: 10px;
    font-weight: bold;
}

.Quiz .question .multimedia img {
    width: 100%;
}
.Quiz .question .text {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: left;
}

.Quiz .question .text button {
    background-color: white;
    border: none;
    position: relative;
    border-radius: 50px;
    color: #ffa600;
    top: -10px;
    height: 50px;
    width: 50px;
    float: right;
    font-size: x-large;
}

.Quiz .question .text button img {
    width: 100%;
}


.Quiz .answergroup {
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Quiz .answergroup button {
    color: #fffcf7;
    font-size: medium;
    background-color: #FFA600;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 50px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.Quiz .answergroup button.selected, .Quiz .answergroup button.selected:hover  {
	background-color: #D03642 !important;
    color: white;
}

.Quiz .navigationgroup {
    width: 50%;
    max-width: 200px;
}

.Quiz .navigationgroup button {
    width: 60%;
    background-color: transparent;
    font-size: xx-large;
    color: #FF9800;
    border: none;
    border-radius: 50px;
    padding: 14px 0px;
    height: auto;
}


.Quiz button.hide {
    display: none;
}


@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
    .Quiz .answergroup {
        display: flex;
    }
    .Quiz .answergroup button {
        font-size: large;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */ 
    /* big landscape tablets, laptops, and desktops */ 
    .Quiz {
        padding-top: 8vh;
        padding-left: 5vw;
        padding-right: 5vw;
        background-color: white;
        border-radius: 50px;
    }
    .Quiz .navigationgroup {
        width: 60%;
    }
    .Quiz .answergroup {
        display: flex;
    }
    .Quiz .answergroup button {
        font-size: large;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .Quiz .question .multimedia img {
        width: 75%;
    }
    .Quiz .question .text button {
        float: right;
    }
    .Quiz p {
        font-size: large;
    }
    .Quiz .progress {
        border-radius: 50px;
    }

}

