/*
    Dark: #292929
    Blue Dark: #1c1964
    Azul: #80FFFF
    Orange: #FFA600
    Red: #D03642

    snow: #FFFCF7;
*/

.App {
    text-align: center;
    min-height: 100vh;
}

body {
	background: linear-gradient(#85D0F5 0%, #F9B004 100%);
	min-height: 100vh;
}

main {
    margin-bottom: 20px;
}

.container {
    max-width: 1000px;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background-color: #FFFCF7;
    margin-top: 80px;
    width: 90%;
    border-radius: 20px;
}

h1, h2, h3, h4, h5 {
	color: #1c1964;
	font-weight: bold;
	font-family: 'Roboto Slab', sans-serif;
}
p, ol {
	color: #1c1964;
	font-size: medium;
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Open Sans', sans-serif;
}
a {
	font-family: 'Roboto Slab', sans-serif;
    color: #FFA600;
    font-weight: bold;
}

/* DipendenteSuper Button */
.ds-button {
    color: #FFFCF7;
    font-weight: bold;
    border-radius: 50px;
    background-color: #FFA600;
    border: none;
    font-size: x-large;
    padding: 10px 20px;
    font-family: 'Roboto Slab', sans-serif;
}

.ds-button:disabled {
    background-color: #E0E2DB;
}


/* Forms */
.form-group {
	text-align: left;
	font-size: large;
	color: #1C1964;
	font-weight: bold;
}

input.form-control {
    background-color: #ecf2fb !important;
    border: none;
    border-radius: 50px;
}

button {
	/*box-shadow: 2px 2px 0px #bfbfbf;*/
}

button:focus {outline:0;}


.modal-content {
	font-family: 'Open Sans';	
    background-color: #FFFCF7;
}







[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    color: #1c1964;
    font-size: medium;
    font-family: 'Open Sans';
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #1c1964;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    main {
        margin-bottom: 0px;
    }
}
@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */ 

    .container {
        padding: 50px 50px;
        margin-top: 40px;
    }

}
