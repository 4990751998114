.Results {
    background-color: #eff2f9;
}

.Results .chartjs-size-monitor {
	width: 100%;
}

.Results .profile {
    padding: 20px;
}

.Results .profile .accordion{
	margin-top: 20px;
}

.Results .profile .accordion p {
	text-align: justify;
}

.Results .profile .accordion button {
	background-color: transparent;
    border: none;
    width: 100%;
    font-family: 'Roboto Slab';
    color: white;
    font-weight: bold;
}

.Results h5 {
    margin-top: 5vh;
}

.Results .shareButtons {
    max-width: 300px;
    margin: auto;
}
.Results .shareButtons div {
    width: 33%;
    display: inline-flex;
}
.Results .shareButtons img {
    width: 70%;
    cursor: pointer;
    margin: auto;
    max-width: 80px;
}
.Results .card {
    border: none;
    margin-bottom: 5px;
    border-radius: 20px;
}

.Results .card-header {
    background-color: rgb(233, 180, 36);
    border-style: none;
}

.Results .tip p{
    text-align: left;
}
.Results .tip ul {
    padding-left: 16px;
    color: #1c196e;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
}

.Results .profile p{
    text-align: left;
}
.Results .profile ul {
    padding-left: 16px;
    color: #1c196e;
    text-align: left;
    font-family: 'Open Sans', sans-serif;
}

@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
    .Results .share img{
    }
    .Results .profile {
        /* padding: 20px; */
        padding-top: 0px;
    }
    .Results .tip ul {
    }
}