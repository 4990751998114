
.EmployeePanel {
	text-align: left;
}
.EmployeePanel .employeeInfo button {
	background-color: #D03642;
    border: none;
    color: #ffffff;
    border-radius: 15px;
    padding: 10px;
    font-weight: bold;
    font-size: large;
	margin-top: 1vh;
}

.EmployeePanel .employeeInfo {
	margin-bottom: 30px;
}

.EmployeePanel .quizPreview {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.EmployeePanel .quizPreview button {
	background-color: #ffc107;
    border: none;
    color: #ffffff;
    border-radius: 15px;
    padding: 10px;
    font-weight: bold;
    font-size: large;
}
.EmployeePanel .quizPreview .quizName {
    font-size: large;
    font-weight: bold;
    color: #1c1964;
}


.EmployeePanel .quizPreview .quizDate {
	float: right;
	color: gray;
}

.EmployeePanel .quizPreview a {
	display: block;
}

