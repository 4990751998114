.Intro {
    padding-top: 20px;
}

.Intro .form-group {
    text-align: left;
}

.Intro .form-group label{

}

.Intro input[type=number] {
	font-size: large;
	border:none;
    color: #1C1964;
    text-align: center;
    border-radius: 50px;
}

.Intro span {
}

.Intro p {
    text-align: left;
}

.Intro ul {
    text-align: left;
    color: #1c1964;
}

/* lampadina */ 
.Intro img {
    float: left;
    width: 40px;
    margin-right: 10px;
    display: block;
}