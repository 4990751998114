.Forms {
	padding-bottom: 20px;
}
.Forms input {
	border:none;
}
.Forms form span {
	text-align: right;
    width: 100%;
    float: right;
    color: #1e1b66;
    font-family: 'Open Sans';
}