.FormsCompany {
	padding-bottom: 20px;
}
.FormsCompany input {
	border:none;
}
.FormsCompany form span {
	text-align: right;
    width: 100%;
    float: right;
    color: #1e1b66;
    font-family: 'Open Sans';
}
