.Email input {
    display: block;
    border-radius: 50px;
    padding: 10px;
    border-style: solid;
    border-color: #e0e2db;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Email button {
}


.Email a {
	display: block;
	margin-top: 5vh;
}

.Email #loadingIcon {
	margin-top: 2vh;
}

.Email .alert {
	margin-top: 2vh;
}