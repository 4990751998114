.Company .howitworks img {
	width: 60%;
}
.Company .howitworks p {
	font-family: 'Roboto Slab';
    font-weight: bold;
    font-size: large;
    margin-bottom: 20px;
    color: #241e46;
    margin-top: 5px;
}
.Company .howitworks p span {
    font-size: x-large;
    padding: 10px;
    color: #241e46;
}
.Company .message {
    padding-bottom: 30px;
}
.Company .calltoaction {
	padding-top: 20px;
}
.Company .calltoaction p {
	margin-bottom: 10px;
}