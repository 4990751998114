.Signup form span {
	text-align: right;
    width: 100%;
    float: right;
    color: #1e1b66;
    font-family: 'Open Sans';
}
.Signup .verify input {
	display: block;
    border-radius: 50px;
    padding: 10px;
    border-style: solid;
    border-color: #e0e2db;
    margin-left: auto;
    margin-right: auto;
	margin-top: 20px;
    margin-bottom: 20px;
}