.MobileMenu {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: #ffffffed;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

.MobileMenu nav a {
    display: block;
    font-size: 3.3vh;
    color: #000040;
}

.MobileMenu nav a.active {
    font-weight: bold;
    color: #d03743;
}


.MobileMenu .Logo {
    margin: auto;
    margin-bottom: 40px;
    margin-top: 40px;
    width: 170px;
}
.MobileMenu .Logo a img{
    width: 70%;
}

@media (min-width: 800px) {
    .MobileMenu {
        display: none;
    }
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}

.MobileMenu div.mobileMenuLabel p {
    font-size: small;
}
.MobileMenu div.mobileMenuLabel {
    position: fixed;
    width: 100%;
    bottom: 5vh;
    display: block;
    left: 0px;
}
.MobileMenu div.mobileMenuLabel img {
    width: 65px;
    margin: 10px;
}