.Home button {
	width: 70%;
	background-color: transparent;
	border: none;
	margin-bottom: 40px;

}
.Home img#brain{
	width: 100%;
    margin-top: 6vh;
}
.Home button img{
	width: 100%;
    margin-top: 10px;
}

.Home button label{
	font-family: 'Roboto Slab';
    font-size: x-large;
    font-weight: bold;
    cursor: pointer;
}

.Home button#dipendente-btn label{
    color: #00839f;
}

.Home button#aziende-btn label{
    color: #f9b004;
}



@media (min-width:1025px) {
    /* big landscape tablets, laptops, and desktops */ 
    .Home.container {
        padding: 70px 50px;
    }
    .Home button {
    	margin-bottom: 0;
	}
}