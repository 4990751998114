#loadingIcon {
	width: 20%;
	max-width: 70px;
    margin-top: 5vh;
}

@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ 
	#loadingIcon {
	    margin-top: 5vh;
	}
}