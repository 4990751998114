.Footer {
    max-width: 1000px;
    padding: 2vh;
    background-color: transparent;
    margin: auto;
    width: 90%;

    text-align: right;
    display: none;
}

.Footer div {
	display: inline-block;
}

.Footer .footerLabel {
	margin-right: 20px;
}
.Footer .footerLabel p {
	font-size: small;
}
.Footer .footerEmail {
	float: right;
}

.Footer .footerEmail a {
	color: #1c1964;
	font-weight: normal;
}

.Footer img {
	width: 70px;
}

@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
	.Footer {
		display: block;
	}
}