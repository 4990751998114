.About img {
	width: 100%;
}
.About p {
	margin-bottom: 1rem;
	text-align: justify;
}

.About h3 {
	margin-bottom: 20px;
}
.About h5 {
	margin-top: 5vh;
}

.About .shareButtons {
	max-width: 300px;
	margin: auto;
}
.About .shareButtons div {
	width: 33%;
	display: inline-flex;
}
.About .shareButtons img {
	width: 70%;
    cursor: pointer;
    margin: auto;
    max-width: 80px;
}