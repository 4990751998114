.Choose .card{
	border: #aeaeae;
    margin: 17px;
    background-color: #fffcf7;
    border-radius: 20px;
    border-style: dashed;
}

.Choose .card .card-title {
	color: #1c1964;
}
