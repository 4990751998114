.CompanyPanel {
	text-align: left;
}

.CompanyPanel #linkContainer {
	margin-top: 30px;
    margin-bottom: 30px;
}

.CompanyPanel #linkContainer input {
	width: 100%;
    color: #ffffff;
    background-color: #838383;
    padding: 5px;
    border: none;
}

.CompanyPanel .companyInfo button {
    background-color: #D03642;
    border: none;
    color: #ffffff;
    border-radius: 15px;
    padding: 10px;
    font-weight: bold;
    font-size: large;
    margin-top: 1vh;
}

.CompanyPanel #linkContainer button{
	border: #e8b426;
    border-width: 5px;
    color: #eab422;
    font-weight: bold;
    border-style: solid;
    margin-top: 10px;
    padding: 10px;
    background-color: transparent;
    border-radius: 50px;
    font-size: large;
    margin-right: 10px;
}

.CompanyPanel #countEmployees {
    background-color: #eeb31a;
    padding: 5px 11px;
    color: white;
    font-weight: bold;
    border-radius: 20px;
}

.CompanyPanel .profile ol {
    padding-left: 16px;
    font-family: 'Open Sans', sans-serif;
}

.CompanyPanel .tip ul {
    padding-left: 16px;
    color: #1c196e;
    font-family: 'Open Sans', sans-serif;
}

.CompanyPanel .chartjs-size-monitor {
    width: 100%;
}

.CompanyPanel .profile {
    padding: 20px;
}

.CompanyPanel .profile .accordion{
    margin-top: 20px;
}

.CompanyPanel .profile .accordion p {
    text-align: justify;
}

.CompanyPanel .profile .accordion button {
    background-color: transparent;
    border: none;
    width: 100%;
    font-family: 'Roboto Slab';
    color: white;
    font-weight: bold;
}

.CompanyPanel .card {
    border: none;
    margin-bottom: 5px;
    border-radius: 20px;
}

.CompanyPanel .card-header {
    background-color: rgb(233, 180, 36);
    border-style: none;
}

@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */
    .CompanyPanel .profile {
        /* padding: 20px; */
        padding-top: 0px;
    }
}